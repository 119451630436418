<template>
  <div class="main">
    <v-btn
      :style="
        !loadingDownload
          ? 'background:#03A9F4;color:white;'
          : 'background: grey; color:white;'
      "
      :disabled="!!loadingDownload"
      @click="onDownloadClicked(false)"
    >
      Download{{ !loadingDownload ? ' ' + config.title : 'ing....' }}
    </v-btn>
  </div>
</template>
    
<script>
export default {
  props: ['configuration', 'dataSource'],
  data: () => ({
    dataProvider: {},
    config: {},
    loader: {},
    loadingDownload: false
  }),
  created: function () {
    console.log('========download==========', this)
    this.config = JSON.parse(JSON.stringify(this.configuration || {}))
    // if (
    //   this.dataSource &&
    //   this.dataSource.headers &&
    //   this.dataSource.headers.length
    // ) {
    //   if (this.dataSource.rows.length) {
    //     this.dataProvider = this.dataSource.rows
    //   } else {
    //     this.dataProvider = this.makeDataProvider(this.dataSource)
    //   }
    // }
  },
  methods: {
    onDownloadClicked: function (clickedForEmail) {
      console.log('onDownloadClicked')
      return
      let maxEventTime = 1000
      this.messageDialogStatus = false
      // this.loading = true;
      if (this.config) {
        let config = {
          chartName: this.config.name,
          title: this.config.title
        }
        if (this.filterApplied) {
          config.exportparams = JSON.stringify(this.filterApplied)
        }

        this.loadingDownload = true
        K.network.downloadFile('xlsx', config)
        this.loader['xlsx'] = setInterval(() => {
          if (
            context.getters.snackbarText == 'Download Successfull' ||
            context.getters.snackbarText == 'Download failed'
          ) {
            this.loadingDownload = false
            this.clearInterval(this.loader['xlsx'])
          }
        }, maxEventTime)
      }
    },
    clearInterval: (t) => {
      clearInterval(t)
    }
  },
  watch: {
    loadingDownload() {
      console.log('LOADING....', this.loadingDownload)
    }
  }
}
</script>